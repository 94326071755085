<template>
    <div class="main-container">
      <h5 class="pageTitle">비행 장소</h5>
  
      <div class="form-container">
       
  
        <div class="form-group">
          <label for="name">비행장소명</label>
          <input v-model="form.name" type="text" class="input-box" placeholder="비행장소명을 입력하세요" />
        </div>
  
       
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-register" @click="registerAirField">등록</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
    name: "AirfieldCreate",
    data() {
      return {
        form: {
          name: "",
        },
      };
    },
  
    methods: {
      // 🛠 비행장소 등록 함수
      async registerAirField() {
        try {
          const token = this.getToken();
  
          // 서버로 보낼 데이터
          const formData = {
            airfield: this.form.name, // 백엔드 필드명과 맞춤
          };
  
          const response = await axios.post("/master/airfields", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (response.status === 201) {
            Swal.fire("성공", "비행장소가 성공적으로 등록되었습니다.", "success");
            this.resetForm();
            this.goToList();
          }
        } catch (error) {
          Swal.fire("오류", "등록 중 문제가 발생했습니다.", "error");
          console.error("비행장소 등록 실패:", error);
        }
      },
  
      // 🛠 토큰 가져오기
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData ? tokenData.access_token : "";
      },
  
      // 🛠 입력 필드 초기화
      resetForm() {
        this.form.name = "";
      },
  
      // 🛠 목록 페이지 이동
      goToList() {
        this.$router.push("/master-airfieldRead");
      },
    },
  };
  </script>
  
  
  <style scoped>
  /* 기존 스타일 유지 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box, .select-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 300px;
  }
  .input-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
  .checkbox {
    margin-top: 10px;
  }
  
  .button-container {
    display: flex;
    gap: 15px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    
  }
  </style>
  